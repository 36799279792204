<template>
  <v-app dark>
    <v-main>
      <router-view
        @darkModeChanged="darkMode"
        @logout="logout"
        @errorOccured="onErrorOccurred"
      />
    </v-main>
    <v-snackbar
      v-model="showErrors"
      timeout="6000"
      top
      right
      color="error"
      multi-line
    >
      <span v-for="(error, index) in errors" :key="index">{{ error }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showErrors = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      :value="message.isVisible"
      @input="removeSuccessMessage(message)"
      v-for="message in successMessages"
      :key="message.id"
      :style="{ 'margin-bottom': calculateSnackbarMargin(message) }"
      timeout="4000"
      color="success"
      bottom
      multi-line
    >
      <span>{{ message.message }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="removeSuccessMessage(message)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { RootState } from "./store";

import EventBus from "./event-bus";
import { AccountApi } from "./openapi";

interface SnackbarMessage {
  id: string;
  isVisible: boolean;
  message: string;
}

const api = new AccountApi(undefined, "");

@Component({})
export default class App extends Vue {
  showErrors = false;
  errors: string[] = [];

  successMessages: SnackbarMessage[] = [];
  clicked = false;

  darkMode() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  logout() {
    api
      .deleteJobsFromEmployee()
      .then((result) => {
        api
          .logout()
          .then((result) => {
            this.$store.commit("setLoggedOut");
            this.$store.commit("setUserRights", []);
            this.$store.commit("setIsBpoUser", false);
            this.$router.push({
              path: "/login",
            });
          })
          .catch((error) => {
            let errors = [error.message];
            this.onErrorOccurred(errors);
          });
      })
      .catch((error) => {
        let errors = [error.message];
        this.onErrorOccurred(errors);
      });
  }

  onErrorOccurred(errors: string[]) {
    this.errors = errors;
    this.showErrors = true;
  }
  onSuccessOccurred(successMessages: string[]) {
    for (const message of successMessages) {
      const newItem = {
        id: `${this.successMessages.length}-${Date.now()}`,
        isVisible: true,
        message: message,
      } as SnackbarMessage;
      this.successMessages.push(newItem);
    }
  }
  removeSuccessMessage(item: SnackbarMessage) {
    const index = this.successMessages.findIndex((c) => c.id === item.id);
    this.successMessages.splice(index, 1);
  }

  calculateSnackbarMargin(item: SnackbarMessage) {
    const index = this.successMessages.findIndex((c) => c.id === item.id);
    return index * 80 + "px";
  }

  created() {
    EventBus.$on("onError", this.onErrorOccurred);
    EventBus.$on("onSuccess", this.onSuccessOccurred);
  }

  destroyed() {
    EventBus.$off("onError", this.onErrorOccurred);
    EventBus.$off("onSuccess", this.onSuccessOccurred);
  }

  clickedSet() {
    if (!this.clicked) {
      this.clicked = true;
    }
  }

  mounted() {
    setInterval(() => {
      const isLoginPage =
        this.$router.currentRoute && this.$router.currentRoute.name == "Login";
      let store = this.$store as Store<RootState>;
      if (!store.state.loggedIn) {
        return;
      }

      if (this.clicked) {
        this.clicked = false;
        api
          .updateUserActive()
          .then((result) => {
            if (!result.data.active) {
              this.logout();
            } else {
              this.$store.commit("setCheckUserStillActive", result.data);
              this.$store.commit("setIsBpoUser", result.data.isBpoUser);
              if (isLoginPage && this.$router.currentRoute.name != "Home") {
                this.$router.push({
                  path: "/",
                });
              }
            }
          })
          .catch((error) => {
            this.onErrorOccurred([error.message]);
          });
      } else {
        api
          .checkUserStillActive()
          .then((result) => {
            if (!result.data.active) {
              this.logout();
              this.onErrorOccurred([
                "You have been logged out due to inactivity",
              ]);
            } else {
              this.$store.commit("setLoggedIn");
              this.$store.commit("setUserRights", result.data.userRights);
              this.$store.commit("setIsBpoUser", result.data.isBpoUser);
            }
          })
          .catch((error) => {
            this.onErrorOccurred([error.message]);
          });
      }
    }, 30000);
    document.addEventListener("click", this.clickedSet);
  }
}
</script>
